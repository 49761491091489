<template>
	<VOverlay
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		:isLarge="true"
		:isVCentered="false"
		:hasFullwidthBody="true"
		:hasScrollableContent="true"
		v-model="overlayIsActive"
		:useFocusTrap="false"
		:closeWithEscape="false"
		:title="$t('cd.composedDocumentList.title')"
	>
		<VTabs v-if="connections.length > 0">
			<VTabItem v-for="(item, index) in connections" :key="index" :title="item.name" :onOpen="() => tabOpened(item)">
				<Suspense>
					<ComposedDocumentList :connGuid="item.conn_guid" />
				</Suspense>
			</VTabItem>
		</VTabs>
	</VOverlay>
</template>

<script>
import { getStoreMutation } from '@assets/scripts/store/config';
import ComposedDocumentList from '@modules/ComposedDocument/materials/structures/ComposedDocumentList.vue';
import { useApiAsync } from '@assets/scripts/composables/useApi';

import {
	GET_CONNECTIONS,
} from '@assets/scripts/api/config';

export default {
	name: 'TheComposedDocumentOverview',
	components: {
		ComposedDocumentList,
	},
	data: function () {
		return {
			overlayIsActive: true,
		};
	},
	async setup () {
		// get all Connections
		const connections = await useApiAsync(GET_CONNECTIONS);
		return { connections };
	},
	methods: {
		tabOpened: function (connection) {
			this.$store.commit(
				getStoreMutation('SET_CURRENT_CONNECTION', 'CD'),
				connection
			);
		},
	},
};
</script>
