<template>
	<div class="page-with-options-wrapper">
		<TheAppOptions
			:backButtonText="$t('cd.documentDetails.backToOverview')"
			:backClickedFn="closeDocument"
			:nameLabel="$t('cd.documentDetails.documentName')"
			v-model:name="documentName"
			@update:name="updateName"
			:saveClickedFn="saveDocument"
			:editMode="canBeEdited"
			:showButtons="showButtons"
			:canBeSaved="canBeSaved"
			:canBePublished="canBePublished"
			:publishClickedFn="publishDocument"
			:canBeValidated="canBeValidated"
			:validateClickedFn="validateDocument"
			:validationErrorListItemIsClickable="false"
			:trimName="true"
		/>

		<div class="page-content js-page-content">
			<ContentBlock
				:size="3"
				:title="$t('cd.documentDetails.documentSettings')"
				:hasBorderUnderTitle="true"
			>
					<VButton
						class="position-right-top"
						variant="is-secondary"
						:text="$t('cd.documentDetails.previewButton')"
						@clicked.prevent.stop="previewClicked"
					/>
				<div class="has-padding-top-1">
					<ConfigOptions
						:options="configOptions"
						:disabled="!canBeEdited"
						v-model:valueModel="configModel"
						@update:valueModel="configChanged"
					/>
				</div>
				<div class="has-padding-top-1">
					<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('cd.documentDetails.description')" />

					<VTextarea
						v-model="document.description"
						:placeholder="$t('cd.documentDetails.description')"
						class="has-margin-bottom-4"
						:isNarrow="true"
						@change="markAsUpdated"
						:disabled="!canBeEdited"
					/>
				</div>
			</ContentBlock>
            <ContentBlock
                :size="3"
                :title="$t('cd.documentDetails.documents')"
                :hasFullwidthBody="true"
            >
                <SortableDataTable
                    :data="docTableRows"
					:scrollable="false"
					defaultSort=""
					:stickyHeader="false"
                    :columns="columns"
                    :emptyText="$t('cd.documentDetails.documentTable.noResults')"
                >	
					<template
                        v-for="(row, key) in docTableRows"
                        :key="key"
                        #[`document-${key}`]
                    >
                        <VField
                            :label="$t('cd.documentDetails.documentTable.chooseDocument')"
                            :isFloating="true"
                            :class="{
                                'is-active has-hidden-label': row.v_model['guid'],
                            }"
							class="has-no-padding-top is-available-width"
                        >
						<VSelect
                                :options="getDocumentOptions(row.parent_guid)"
                                v-model="row.v_model['guid']"
                                @update:modelValue="selectedDocChanged(row.v_model)"
                                :disabled="disabledDocSelect(row)"
								:classes="getDocumentOptions(row.parent_guid).value.length === 0 ? 'hide-row': ''"
                            />
                        </VField>
                    </template>
					<template
                        v-for="(row, key) in docTableRows"
                        :key="key"
                        #[`method-${key}`]
                    >
                         <VField
                            :label="$t('cd.documentDetails.documentTable.chooseMethod')"
                            :isFloating="true"
                            :class="{
                                'is-active has-hidden-label': row.v_model['method'],
                            }"
							class="has-no-padding-top is-available-width"
                        >
                            <VSelect
                                :options="getMethodOptions(row.guid)"
                                v-model="row.v_model['method']"
                                @update:modelValue="markAsUpdated"
                                :disabled="disabledMethodSelectAndNameInput(row)"
                            />
                        </VField>
                    </template>
                    <template
                        v-for="(row, key) in docTableRows"
                        :key="key"
                        #[`child_name-${key}`]
                    >
						<VInputString
							v-if="row.level > 0"
                        	:key="`${renderCounter}-${key}`"
							:labelHidden="true"
							v-model:valueModel="row.v_model['child_name']"
							:label="$t('cd.documentDetails.documentTable.childNameLabel')"
							:trim="true"
							:disabled="disabledMethodSelectAndNameInput(row)"
							@update:valueModel="markAsUpdated"
							fieldClass="has-no-padding-top is-available-width"
						/>
						<template v-else>-</template>
                    </template>
                </SortableDataTable>
            </ContentBlock>
		</div>
	</div>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { reactive, toRefs, ref, isRef } from '@vue/reactivity';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import TheAppOptions from '@materials/structures/TheAppOptions.vue';
import { composedDocumentMeta } from '@modules/ComposedDocument/endpoints';
import Helpers from '@assets/scripts/helpers';
import { useApi } from '@assets/scripts/composables/useApi';

import {
	GET_ACTION_DOCUMENT,
	GET_ACTION_DOCUMENTS,
	GET_REFERENCING_DOCUMENTS
} from '@modules/ComposedDocument/endpoints';

export default {
	name: 'TheComposedDocumentDetails',
	components: {
		TheAppOptions,
	},
	data: function () {
		return {
			documentName: '',
			renderCounter: 0,
			actionDocuments: {
				true: {},
				false: {},
			},
			rootDocuments: {
				true: [],
				false: [],
			},
			referencingDocuments: {
				true: {},
				false: {},
			},
			columns: [
				{
					label: this.$t('cd.documentDetails.documentTable.columns.document'),
					field: 'document',
					sortable: false,
					leveled: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('cd.documentDetails.documentTable.columns.method'),
					field: 'method',
					sortable: false,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('cd.documentDetails.documentTable.columns.childName'),
					field: 'child_name',
					sortable: false,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteDocClicked,
				},
			],
		};
	},
	setup: function () {
		const store = useStore();
		const { t } = useI18n();

		const rootDocumentMeta = composedDocumentMeta.document.children;
		const childDocumentMeta = composedDocumentMeta.document.children.childs.children;

		const state = reactive({
			/**
			 * Gets currently active Composed Document
			 */
			document: store.getters[getStoreGetter('CURRENT_COMPOSED_DOCUMENT', 'CD')],
			/**
			 * Boolean to indicate whether current composed document
			 * can be edited
			 */
			canBeEdited: store.getters[getStoreGetter('CAN_BE_EDITED', 'CD')],
			/**
			 * Boolean to indicate whether current composed document
			 * can be extended
			 */
			canBeExtended: store.getters[getStoreGetter('CAN_BE_EXTENDED', 'CD')],
			configOptions: [
				{
					value: 'is_read',
					label: t('cd.documentDetails.settings.is_read'),
					info: t('cd.documentDetails.settings.is_read_info'),
				},
			],
			configModel: {},
			docTableRows: [],
		});

		state.configOptions.forEach((option) => {
			if (typeof state.document[option.value] !== 'undefined') {
				state.configModel[option.value] = state.document[option.value];
			}
		});

		// Make emtpy childs for each document
		const makeEmptyChildsWhenNeeded = () => {
			// loop over document childs and add empty child for each document
			const addChildsRecursive = (document) => {
				// hasEmptyChild needed to check later if the document has already an empty child
				// in this module it is possible for documents to have unlimeted childs and the childs to have ulimeted childs
				let hasEmptyChild = false;
				document.childs.forEach((child) => {
					if (child.childs && child.guid) {
						addChildsRecursive(child);
					}
					if (!child.guid) {
						hasEmptyChild = true;
					}
				});
				// if has already an empty child dont add one
				if (!hasEmptyChild && document.guid) {
					// make new child object form meta and add mising properties
					const newChild = Helpers.obj.create(childDocumentMeta, {
						childs: [],
						is_new: true,
					});
					document.childs.push(newChild);
				}
			};

			addChildsRecursive(state.document.document);
		};

		// Recursivly add rows for each document to be used in  sortableTable component
		const calculateDocTableRows = () => {
			const rows = [];

			// push root document in rows array
			rows.push({
				document: state.document.document.name,
				guid: state.document.document.guid,
				method: state.document.document.method,
				v_model: state.document.document,
				delete: false,
				methodOptions: document.methods,
				level: 0,
			});

			// loop over document childs recursivly and add row for each child
			const addRowsRecursive = (document, level) => {
				document.childs.forEach((row) => {
					rows.push({
						parent_guid: document.guid,
						document: row.name,
						key_field: row.key_field,
						child_field: row.child_field,
						child_name: row.child_name,
						guid: row.guid,
						v_model: row,
						method: row.method,
						delete: row.guid && (state.canBeEdited || row.is_new),
						level: level,
						is_new: row.is_new,
						disabled: !state.canBeEdited && !row.is_new,
					});
					if (row.childs) {
						addRowsRecursive(row, level + 1);
					}
				});
			};

			addRowsRecursive(state.document.document, 1);
			// add key property to each row for rendering
			const returnRows = rows.map((row, key) => {
				row.key = key;
				return row;
			});
			state.docTableRows = returnRows;
		};

		makeEmptyChildsWhenNeeded();
		calculateDocTableRows();

		return {
			...toRefs(state), makeEmptyChildsWhenNeeded, calculateDocTableRows, rootDocumentMeta, childDocumentMeta
		};
	},
	mounted: function () {
		this.documentName =
			this.document && this.document.name
				? this.document.name
				: '';

		// clear validation when composed document is loded
		this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether user modified the composed document
			 */
			modified: getStoreGetter('MODIFIED', 'CD'),
			/**
			 * Boolean to indicate whether current composed document
			 * can be saved
			 */
			canBeSaved: getStoreGetter('CAN_BE_SAVED', 'CD'),
			/**
			 * Get current connection
			 */
			currentConnection: getStoreGetter('CURRENT_CONNECTION', 'CD'),
			/**
			 * Boolean to indicate whether current composed document
			 * can be published
			 */
			canBePublished: getStoreGetter('CAN_BE_PUBLISHED', 'CD'),
		}),
		showButtons: function () {
			return this.canBeEdited || this.canBeExtended;
		},
		canBeValidated: function () {
			return this.canBeEdited || this.canBePublished || this.canBeExtended;
		},
	},
	methods: {
		previewClicked() {
			// open drawer to preview document json
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'documentJsonPreview'
			});
		},
		markAsUpdated: function () {
			if (this.modified) return;
			// mark document as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'CD')
			);
		},
		docUpdated: function () {
			this.renderCounter++;
			this.makeEmptyChildsWhenNeeded();
			this.calculateDocTableRows();
			this.markAsUpdated();
		},
		// Get method options for VSelect component from an api call to the document within an connection
		getMethodOptions: function (guid) {
			if (!guid) return;

			// using ref method here because we want to return methodOptions directly and update it when data is fetched from the API
			let methodOptions = ref([]);

			const setOptions = (actionDocument) => {
				methodOptions.value = actionDocument.methods.map((row) => {
					return {
						text: row,
						value: row,
					}
				});
			}

			// get action document object
			const actionDocument = this.getActionDocument(guid)
			
			// actionDocument is a ref when it comes from the API and it's a normal object when it's from the cache
			// check if the actionDocument is a refrence object using isRef() method from vue
			// if actionDocument is ref then we should watch actionDocument and use it when actionDocument is availble
			if(isRef(actionDocument)) {
				watch(actionDocument, () => {
					setOptions(actionDocument.value);
				});
			} else {
				setOptions(actionDocument);
			}

			return methodOptions;
		},
		// get document options for VSelect component form an api call to get documents that are read or not read documents based on is_read from the composed document within an connection
		getDocumentOptions: function (parent_guid) {
			// using ref method here because we want to return docOptions directly and update it when data is fetched from the API
			let docOptions = ref([]);

			const setOptions = (data) => {
				//Fill the docOptions ref object with the options for VSelect component
				docOptions.value = data.map((row) => {
					return {
						text: row.name,
				 		value: row.guid,
					}
				});
			}

			if (parent_guid) {
				// get referencing action documents array
				const data = this.getReferencingDocuments(parent_guid);

				// actionDocument is a ref when it comes from the API and it's a normal object when it's from the cache
				// check if the actionDocument is a refrence object using isRef() method from vue
				// if actionDocument is ref then we should watch actionDocument and use it when actionDocument is availble
				if(isRef(data)) {
					watch(data, () => {
						setOptions(data.value);
					});
				} else {
					setOptions(data);
				}
			} else {
				// get action documents array
				const data = this.getRootDocuments();

				// actionDocument is a ref when it comes from the API and it's a normal object when it's from the cache
				// check if the actionDocument is a refrence object using isRef() method from vue
				// if actionDocument is ref then we should watch actionDocument and use it when actionDocument is availble
				if(isRef(data)) {
					watch(data, () => {
						setOptions(data.value);
					});
				} else {
					setOptions(data);
				}
			}
			return docOptions;
		},
		configChanged: function () {
			// update document with new values of the config
			Object.assign(this.document, this.configModel);
			// make root document empty when config change
			Object.assign(
				this.document.document,
				Helpers.obj.create(this.rootDocumentMeta)
			);
			this.docUpdated();
		},
		selectedDocChanged: function (doc) {
			const setDetails = (actionDocument) => {
				// check if child document and not root document
				// if doc has child_name then is it a child document
				if (typeof doc.child_name === 'string') {
					Object.assign(
						doc,
						Helpers.obj.create(this.childDocumentMeta, {
							guid: doc.guid,
							childs: [],
							name: actionDocument.name,
							child_name: actionDocument.name,
							key_field: actionDocument.key_field,
							child_field: actionDocument.child_field,
						})
					);
				} else {
					Object.assign(
						doc,
						Helpers.obj.create(this.rootDocumentMeta, {
							guid: doc.guid,
							childs: [],
							name: actionDocument.name,
						})
					);
				}
				this.docUpdated();
			}

			// get action document object
			const actionDocument = this.getActionDocument(doc.guid);

			// actionDocument is a ref when it comes from the API and it's a normal object when it's from the cache
			// check if the actionDocument is a refrence object using isRef() method from vue
			// if actionDocument is ref then we should watch actionDocument and use it when actionDocument is availble
			if(isRef(actionDocument)) {
				watch(actionDocument, () => {
					setDetails(actionDocument.value);
				});
			} else {
				setDetails(actionDocument);
			}
		},
		// get action document from the cache if it's not in the cache get it from the api and cache it
		getActionDocument(guid) {
			// search first for the action document in the cache 
			const cachedActionDocument = this.actionDocuments[this.document.is_read][guid] || false;
			// action document found in the cache return that document
			if(cachedActionDocument){
				// returnd casched action document as an object
				return cachedActionDocument;
			} else {
				// fetch data from API filtered by connection guid, document guid and is_read from composed document
				const { data } = useApi(GET_ACTION_DOCUMENT, {
					keys: {
						connection: this.currentConnection,
						guid: guid,
					},
					parameters: {
						read: this.document.is_read,
					},
				});
				watch(data, () => {
					this.actionDocuments[this.document.is_read][data.value.guid] = data.value;
				});
				// returned data is a ref object that contains the action document inside _value property
				return data;
			}
		},
		// get action documents from the cache if it's not in the cache get it from the api and cache it
		getRootDocuments() {
			let cachedrootDocuments = false;
			// search first in the cache 
			if (this.rootDocuments[this.document.is_read].length > 0) {
				cachedrootDocuments = this.rootDocuments[this.document.is_read];
			}
			// action documents found in the cache return that array
			if(cachedrootDocuments){
				// returnd casched action document as an array
				return cachedrootDocuments;
			} else {
				// fetch data from API filtered by connection guid and is_read from the composed document
				const { data } = useApi(GET_ACTION_DOCUMENTS, {
					keys: {
						connection: this.currentConnection,
					},
					parameters: {
						read: this.document.is_read,
					},
				});
				watch(data, () => {
					this.rootDocuments[this.document.is_read] = data.value;
				});
				return data;
			}
		},
		// get referencing action documents from the cache if it's not in the cache get it from the api and cache it
		getReferencingDocuments(parent_guid) {
			// search first in the cache 
			const cachedReferencingDocuments = this.referencingDocuments[this.document.is_read][parent_guid] || false;
			// referencing action documents found in the cache return that documents
			if(cachedReferencingDocuments){
				// returnd casched referencing action documents as an array
				return cachedReferencingDocuments;
			} else {
				// get all documents that have a reference to the parent document using it's guid
				// fetch data from API filtered by connection guid, parent guid and is_read from the composed document
				const { data } = useApi(GET_REFERENCING_DOCUMENTS, {
					keys: {
						connection: this.currentConnection,
						guid: parent_guid,
					},
					parameters: {
						read: this.document.is_read,
					},
				});
				watch(data, () => {
					this.referencingDocuments[this.document.is_read][parent_guid] = data.value;
				});
				return data;
			}
		},
		disabledDocSelect: function (row) {
			// doc select and child name input is disabled when composed document can't be edited and row is not new,
			return (!this.canBeEdited  && !row.is_new || this.getDocumentOptions(row.parent_guid).value.length === 0);
		},
		disabledMethodSelectAndNameInput: function (row) {
			// method select is disabled when composed document can't be edited and row is not new and document is noet selected,
			return this.disabledDocSelect(row) || !row.guid;
		},
		closeDocument: function () {
			const closeOverlay = () => {
				// dispatch action to unset composed document
				this.$store.dispatch(getStoreAction('UNSET_COMPOSED_DOCUMENT', 'CD'));
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeOverlay();
			} else {
				// ask confirmation before closing if a change has been made
				// to the composed document
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('cd.documentDetails.close.confirm.title'),
					body: this.$t('cd.documentDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'cd.documentDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeOverlay();
					},
				});
			}
		},
		validateDocument: function () {
			// dispatch action to Validate composed document
			this.$store.dispatch(
				getStoreAction('VALIDATE_CURRENT_COMPOSED_DOCUMENT', 'CD')
			);
		},
		saveDocument: function () {
			this.$store.dispatch(
				getStoreAction('SAVE_CURRENT_COMPOSED_DOCUMENT', 'CD')
			);
		},
		deleteDocClicked: function ({ guid }) {
			// ask confirmation to delete document
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('cd.documentDetails.delete.confirm.title'),
				body: this.$t('cd.documentDetails.delete.confirm.body'),
				confirmButtonText: this.$t(
					'cd.documentDetails.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					// find the right object recursivly using its guid and delete it
					const findAndDeleteChildRecursive = (document) => {
						let foundTargetChild = false;
						document.childs.forEach((child) => {
							if (child.childs && child.guid) {
								findAndDeleteChildRecursive(child);
							}
							if (child.guid === guid) {
								foundTargetChild = true;
							}
						});
						if (foundTargetChild) {
							// getting the index of the object we want to delete
							const indexOfObject = document.childs.findIndex(
								(object) => {
									return object.guid === guid;
								}
							);
							// delete target object from the parentDocument.childs array
							document.childs.splice(indexOfObject, 1);
						}
					};
					findAndDeleteChildRecursive(this.document.document);
					// update composed document
					this.docUpdated();
				},
			});
		},
		publishDocument: function () {
			// ask confirmation before publishing
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('cd.documentDetails.publish.confirm.title'),
				body: this.$t('cd.documentDetails.publish.confirm.body'),
				confirmButtonText: this.$t(
					'cd.documentDetails.publish.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to publish composed document
					this.$store.dispatch(
						getStoreAction('PUBLISH_CURRENT_COMPOSED_DOCUMENT', 'CD')
					);
				},
			});
		},
		updateName: function (newName) {
			// commit mutation in store
			this.$store.commit(getStoreMutation('SET_NAME', 'CD'), newName);
		},
	},
};
</script>
