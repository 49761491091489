<template>
	<div class="app-layer" :class="{ 'is-active': showComposedDocDetails }">
		<TheComposedDocumentDetails v-if="showComposedDocDetails" />
	</div>
	<div
		class="overlay-layer app-layer"
		:class="{ 'is-active': !showComposedDocDetails }"
	>
		<NewComposedDocument v-if="showNewComposedDocumentModal" />

		<Suspense>
			<TheComposedDocumentOverview v-show="showOverview" />
		</Suspense>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import TheComposedDocumentDetails from '@modules/ComposedDocument/materials/structures/TheComposedDocumentDetails.vue';
import TheComposedDocumentOverview from '@modules/ComposedDocument/materials/TheComposedDocumentOverview.vue';

import NewComposedDocument from '@modules/ComposedDocument/materials/structures/NewComposedDocument.vue';

export default {
	name: 'TheComposedDocument',
	components: {
		TheComposedDocumentDetails,
		TheComposedDocumentOverview,
		NewComposedDocument,
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the form to
			 * create a new composed document should be displayed
			 */
			showCreateNew: getStoreGetter('SHOW_CREATE_NEW', 'CD'),
			/**
			 * Boolean to indicate whether a composed document is
			 * currently active
			 */
			composedDocumentActive: getStoreGetter('COMPOSED_DOCUMENT_ACTIVE', 'CD'),
		}),
		canEditComposedDoc: function () {
			return usePermission('Upsert', 'ComposedDocument');
		},
		canReadComposedDoc: function () {
			return usePermission('Read', 'ComposedDocument');
		},
		showNewComposedDocumentModal: function () {
			return this.showCreateNew && this.canEditComposedDoc;
		},
		showComposedDocDetails: function () {
			return !this.showNewComposedDocumentModal && this.composedDocumentActive && this.canReadComposedDoc;
		},
		showOverview: function () {
			return !this.showNewComposedDocumentModal && !this.showComposedDocDetails && this.canReadComposedDoc;
		}
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'CD'));
	},
};
</script>
