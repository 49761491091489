<template>
	<div class="content-header has-bottom-divider has-padding-top-3 has-margin">
		<div class="field is-grouped is-flex-grow-1 is-align-items-end">
			<VLink
				v-if="canEditComposedDoc"
				:text="$t('cd.docList.addComposedDocument')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="createNewClicked"
				class="has-margin-right-3 has-margin-bottom-05"
			/>

			<VSearch v-model="search" />
		</div>
	</div>

	<div class="scrollable-content">
		<SortableDataTable
			:scrollable="false"
			:rowClickedFn="rowClicked"
			defaultSort="last_time_edited"
			sortDirection="DESC"
			:data="filteredData"
			:columns="columns"
			:emptyText="$t('cd.docList.table.noResults')"
		/>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from '@vue/reactivity';
import { getStoreAction, getStoreMutation } from '@assets/scripts/store/config';
import { formatForTable } from '@modules/ComposedDocument/components/composed-document';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { useCreateNewOnKeydown } from '@assets/scripts/composables/useCreateNewOnKeydown';
import usePermission from '@assets/scripts/composables/usePermission';

import {
	GET_COMPOSED_DOCUMENTS,
} from '@modules/ComposedDocument/endpoints';

export default {
	name: 'ComposedDocumentList',
	props: {
		connGuid: {
			type: String,
			required: true,
		}
	},
	data: function () {
		return {
			search: '',
		};
	},
	async setup (props) {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			documents: [],
			canEditComposedDoc: usePermission('Upsert', 'ComposedDocument'),
		});

		const createNewClicked = () => {
			store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'CD'),
				true
			);
		};
		if(state.canEditComposedDoc) {
			useCreateNewOnKeydown(createNewClicked);
		}

		const loadDocuments = async () => {
			// get all Connection Documents
			state.documents = await useApiAsync(GET_COMPOSED_DOCUMENTS, {
				keys: {
					connection: props.connGuid
				}
			});
		};

		// subscribe to store action
		const unsubscribeAction = store.subscribeAction({
			after: async ({ type, payload }) => {
				// check action type and relevant connection GUID
				if (
					type === getStoreAction('UPDATE_COMPOSED_DOCUMENT_LIST', 'CD') &&
					payload === props.connGuid
				) {
					// reload document list if needed
					await loadDocuments(true);
				}
			}
		});

		await loadDocuments();

		return { ...toRefs(state), unsubscribeAction, createNewClicked };
	},
	computed: {
		columns: function() {
			return [
				{
					label: this.$t('cd.docList.table.columns.doc_name'),
					field: 'doc_name',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				},
				{
					label: this.$t('cd.docList.table.columns.is_read'),
					field: 'is_read',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				},
				{
					label: this.$t('cd.docList.table.columns.root_child'),
					field: 'root_child',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				},
				{
					label: this.$t('cd.docList.table.columns.last_time_edited'),
					field: 'last_time_edited',
					sortable: true,
					searchable: true,
					isDate: true,
					// width: '25%',
				},
				{
					label: this.$t('cd.docList.table.columns.status'),
					field: 'status',
					sortable: true,
					searchable: true,
					// width: '15%',
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.rowClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			];
		},
		tableFields: function () {
			return formatForTable(this.documents);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// loop over all documents
			const newData = this.tableFields;

			// return if no rows match
			if (this.search.length === 0 || newData.length === 0) return newData;

			// filter on search string
			return Helpers.filterByString(
				newData,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		rowClicked({ guid }) {
			// dispatch action to show clicked document
			this.$store.dispatch(getStoreAction('LOAD_AND_SHOW_COMPOSED_DOCUMENT', 'CD'), {
				guid,
				conn_guid: this.connGuid,
			});
		},
		deleteClicked({ guid }) {
			// ask confirmation to delete document
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('cd.docList.delete.confirm.title'),
				body: this.$t('cd.docList.delete.confirm.body'),
				confirmButtonText: this.$t(
					'cd.docList.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to delete clicked document
					this.$store.dispatch(
						getStoreAction('DELETE_COMPOSED_DOCUMENT', 'CD'),
						{
							guid,
							conn_guid: this.connGuid,
						}
					);
				},
			});
		},
		sortByDate(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.time, b.time, isAsc);
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
